import React from "react";
import experienceImg from "../../../assets/images/experience.webp";

const Experience = () => {
  return (
    <>
      <section className="experienceSec sectionPD bgGray">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="ExperienceText">
                <div className="sectionTitle">
                  <h3>20 Years of Experience in E-Rickshaw & Bike Manufactures</h3>
                  <p>
                    “Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna”
                  </p>
                </div>

                <ul>
                  <li>
                    <div className="nameInfo">
                      <h6 className="name">Quality E-Rickshaw</h6>
                      <span className="number">89%</span>
                    </div>
                    <div className="barInfo">
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={89}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: "89%" }}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nameInfo">
                      <h6 className="name">Fully Insured</h6>
                      <span className="number">86%</span>
                    </div>
                    <div className="barInfo">
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={86}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: "86%" }}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nameInfo">
                      <h6 className="name">Afordable Pricing</h6>
                      <span className="number">82%</span>
                    </div>
                    <div className="barInfo">
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={82}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: "82%" }}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="nameInfo">
                      <h6 className="name">Easy Booking</h6>
                      <span className="number">86%</span>
                    </div>
                    <div className="barInfo">
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={86}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: "86%" }}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="expFigure">
                <img
                  src={experienceImg}
                  alt="experience"
                  className="img-fluid"
                />
                <div className="text">
                <h5>We Provide Best Vehicle</h5>
                  <hr />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua Ut enim ad minim
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Experience;
