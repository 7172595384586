import React from 'react'
import Vehicles from '../../components/vehicles/Vehicles'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const VehiclesPage = () => {
  return (
    <>
      <BreadCrumb title="Our Vehicles" />
      <Vehicles />
      
    </>
  )
}

export default VehiclesPage