import React from "react";
import { Link } from "react-router-dom";
import about from "../../../assets/images/about.webp";

const About = () => {
  return (
    <>
      <section className="aboutSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
                <div className="aboutImg">
                    <img src={about} alt="About Us" className="img-fluid" />
                    <div className="text">
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna”</p>
                        <h5>Zavier Garner</h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-7">
              <div className="aboutContent">
                <h6>WHAT WE DO!</h6>
                <h2>
                  Manufacture Service With A Wide Range Of Vehicles To Accommodate
                  You
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris
                </p>
                <ul>
                  <li>Many Types E-Rickshaw</li>
                  <li>Manufacture Fully Insured</li>
                  <li>Delivery Service</li>
                  <li>Online Booking</li>
                </ul>
                <Link className="readMore " to={"/"}>
                  read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
