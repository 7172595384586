import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

import blog1 from "../../../assets/images/blog/1.webp";
import blog2 from "../../../assets/images/blog/2.webp";
import blog3 from "../../../assets/images/blog/3.webp";
import blog4 from "../../../assets/images/blog/4.webp";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <section className="blogSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="sectionTitle">
                <h6>Our Blog</h6>
                <h2>Latest Blog & Articles</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="blogCard">
                <div className="blogImg">
                  <img src={blog1} alt="Blog" className="img-fluid" />
                </div>
                <div className="blogContent">
                  <h5>8 Reasons To Manufacture A E-Rickshaw While You Traveling</h5>
                  <span className="time"> <i className="ri-time-line"></i> September 28, 2021</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore
                  </p>
                  <Link>
                    Get a Quote <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blogCard">
                <div className="blogImg">
                  <img src={blog2} alt="Blog" className="img-fluid" />
                </div>
                <div className="blogContent">
                  <h5>A Comprehensive Guide To Manufacture A Scooter In Bali</h5>
                  <span className="time"> <i className="ri-time-line"></i> September 28, 2021</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore
                  </p>
                  <Link>
                    Get a Quote <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blogCard">
                <div className="blogImg">
                  <img src={blog3} alt="Blog" className="img-fluid" />
                </div>
                <div className="blogContent">
                  <h5>How To Manufacture A E-Rickshaw In Bali: Know Your Options</h5>
                  <span className="time"> <i className="ri-time-line"></i> September 28, 2021</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore
                  </p>
                  <Link>
                    Get a Quote <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blogCard">
                <div className="blogImg">
                  <img src={blog4} alt="Blog" className="img-fluid" />
                </div>
                <div className="blogContent">
                  <h5>5 Best E-Rickshaw Road Trips In Bali That You Should Use</h5>
                  <span className="time"> <i className="ri-time-line"></i> September 28, 2021</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore
                  </p>
                  <Link>
                    Get a Quote <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
