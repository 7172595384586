import React from 'react'
import Login from '../../components/login/Login'

const LoginPage = () => {
  return (
    <>
        <Login />
    </>
  )
}

export default LoginPage