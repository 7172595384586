import React from 'react'
import Condition from '../../components/condition/Condition'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const ConditionPage = () => {
  return (
    <>
        <BreadCrumb title="Terms & Condition" />
        <Condition />
    </>
  )
}

export default ConditionPage