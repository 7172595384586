import React from 'react'
import Video from '../../components/video/Video'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const VideoPage = () => {
  return (
    <>
        <BreadCrumb title="Video Gallery" />
        <Video />
    </>
  )
}

export default VideoPage