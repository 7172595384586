import React from "react";
import gallery1 from "../../../assets/images/gallery/1.webp";
import gallery2 from "../../../assets/images/gallery/2.webp";
import gallery3 from "../../../assets/images/gallery/3.webp";
import gallery4 from "../../../assets/images/gallery/4.webp";
import gallery5 from "../../../assets/images/gallery/5.webp";
import gallery6 from "../../../assets/images/gallery/6.webp";

const Gallery = () => {
  return (
    <>
      <section className="gallerySec bgGray sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle text-center">
                <h6>Our Gallery</h6>
                <h2>Captured Moments From Customers</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="galleryCard">
                <div className="galleryImg">
                  <img src={gallery1} alt="Gallery" className="img-fluid" />
                </div>
                <div className="galleryImg">
                  <img src={gallery2} alt="Gallery" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="galleryCard">
                <div className="galleryImg">
                  <img src={gallery3} alt="Gallery" className="img-fluid" />
                </div>
                <div className="galleryImg">
                  <img src={gallery4} alt="Gallery" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="galleryCard">
                <div className="galleryImg">
                  <img src={gallery5} alt="Gallery" className="img-fluid" />
                </div>
                <div className="galleryImg">
                  <img src={gallery6} alt="Gallery" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
