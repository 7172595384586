import React from "react";
import VehicleImg from "./vehicle-img/VehicleImg";
import world from "../../assets/images/cover/world.png";
import { Tab, Tabs } from "react-bootstrap";
import ColorDegreeView from "./color-degree-iew/ColorDegreeView";


const VehicleDetails = () => {
  return (
    <>
      <section className="vehicleSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <VehicleImg />
              <div className="addInfo">
                <div className="name">
                  <h4>Vespa Piajjio 125</h4>
                </div>
                <div className="price">
                  {/* <span>₹55</span>
                  <p>/Day</p> */}
                  <a href="">Download Broucher</a>
                </div>
              </div>

              <div className="vehicleTab">
                <Tabs defaultActiveKey="Engine" id="uncontrolled-tab-example">
                  <Tab eventKey="Engine" title="Engine">
                    <div className="innerTabCard">
                      <ul className="listInfo">
                        <li>
                          <h6>Type</h6>
                          <p>Air cooled, 4-stroke, Single cylinder, OHC</p>
                        </li>
                        <li>
                          <h6>Max. Power</h6>
                          <p>5.9 kW @ 8000 rpm</p>
                        </li>
                        <li>
                          <h6>Bore x Stroke</h6>
                          <p>50.0 x 49.5 mm</p>
                        </li>
                        <li>
                          <h6>Fuel System</h6>
                          <p>Advanced Programmed Fuel Injection</p>
                        </li>
                        <li>
                          <h6>Displacement</h6>
                          <p>97.2 cc</p>
                        </li>
                        <li>
                          <h6>Max. Torque</h6>
                          <p>8.05 Nm @ 6000 rpm</p>
                        </li>
                        <li>
                          <h6>Starting</h6>
                          <p>Kick-start, Self Start</p>
                        </li>
                      </ul>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Transmission & Chassis"
                    title="Transmission & Chassis"
                  >
                    <div className="innerTabCard">
                      <ul className="listInfo">
                        <li>
                          <h6>Clutch</h6>
                          <p>Multiplate Wet Type</p>
                        </li>
                        <li>
                          <h6>Frame Type</h6>
                          <p>Tubular Double Cradle</p>
                        </li>
                        <li>
                          <h6>Transmission</h6>
                          <p>4-speed Constant Mesh</p>
                        </li>
                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="Suspension" title="Suspension">
                    <div className="innerTabCard">
                      <div className="spec">
                        <ul className="listInfo">
                          <li>
                            <h6>Front</h6>
                            <p>Telescopic Hydraulic Shock Absorbers</p>
                          </li>
                          <li>
                            <h6>Rear</h6>
                            <p>
                              Swingarm with 2-Step Adjustable Hydraulic Shock
                              Absorbers
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Brakes" title="Brakes">
                    <div className="innerTabCard">
                      <div className="spec">
                        <ul className="listInfo">
                          <li>
                            <h6>Front</h6>
                            <p>Drum (130 mm)</p>
                          </li>
                          <li>
                            <h6>Rear</h6>
                            <p>Drum (130 mm), Integrated Braking System</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Wheels & Tyres" title="Wheels & Tyres">
                    <div className="innerTabCard">
                      <div className="spec">
                        <ul className="listInfo">
                          <li>
                            <h6>Front Tyre</h6>
                            <p>2.75 x 18 - 4PR/42P</p>
                          </li>
                          <li>
                            <h6>Rear</h6>
                            <p>2.75 x 18 - 6PR/48P</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Electricals" title="Electricals">
                    <div className="innerTabCard">
                      <ul className="listInfo">
                        <li>
                          <h6>Battery</h6>
                          <p>MF Battery, 12V - 3Ah</p>
                        </li>
                        <li>
                          <h6>Head Lamp</h6>
                          <p>12 V - 35 / 35 W (Halogen Bulb),Trapezoidal MFR</p>
                        </li>
                        <li>
                          <h6>Turn Signal Lamp</h6>
                          <p>12V - 10W x 4 - MFR</p>
                        </li>
                        <li>
                          <h6>Tail/Stop Lamp</h6>
                          <p>12V -5 / 10W - MFR</p>
                        </li>
                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="Dimensions" title="Dimensions">
                    <div className="innerTabCard">
                      <ul className="listInfo">
                        <li>
                          <h6>Length X Width X Height</h6>
                          <p>1965X 720 X 1045 mm</p>
                        </li>
                        <li>
                          <h6>Wheelbase</h6>
                          <p>1235 mm</p>
                        </li>
                        <li>
                          <h6>Fuel Tank Capacity</h6>
                          <p>9.6 Litre</p>
                        </li>
                        <li>
                          <h6>Saddle Height</h6>
                          <p>805 mm</p>
                        </li>
                        <li>
                          <h6>Ground Clearance</h6>
                          <p>165 mm</p>
                        </li>
                        <li>
                          <h6>Kerb Weight</h6>
                          <p>110 kg (Kick) | 112 kg (Self)</p>
                        </li>
                      </ul>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className="bookFormBox d-none">
                <h3>Booking Now</h3>
                <form className="bookForm row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Name">Name</label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Phone Number">Phone Number</label>
                      <input
                        type="number"
                        placeholder="Your Phone Number"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Pick Up Date">Pick Up Date</label>
                      <input
                        type="date"
                        placeholder="Select Date"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Pick Up Location">Pick Up Location</label>
                      <select className="form-select">
                        <option defaultValue="Vespa">Vespa</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Email">Email</label>
                      <input
                        type="mail"
                        placeholder="Your Email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Vehicle">Vehicle</label>
                      <select className="form-select">
                        <option defaultValue="Vespa">Vespa</option>
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="Return Date">Return Date</label>
                      <input
                        type="date"
                        placeholder="Select Date"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label
                        htmlFor="Return Date Location
                      "
                      >
                        Return Date Location
                      </label>
                      <select className="form-select">
                        <option defaultValue="Vespa">
                          Return Date Location
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control "
                        placeholder="Additional Information (Optional) "
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                    <div className="form-group mb-3">
                      <button className="readMore b-0">Book now</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="positionSticky">
                <div className="colorOption">
                  <h5>Color</h5>
                  <ul>
                    <li className="green">
                      <div className="color"></div>
                      <span>Green</span>
                    </li>
                    <li className="red">
                      <div className="color"></div>
                      <span>red</span>
                    </li>
                    <li className="blue">
                      <div className="color"></div>
                      <span>blue</span>
                    </li>
                  </ul>
                </div>
                <div className="vehicleBox">
                  <h4>Ride more save more</h4>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit
                  </p>
                  <ul className="elementIconList">
                    <li>
                      <span className="elementText">1 - 3 Days</span>
                      <strong className="elementBold">Standard Rate</strong>
                    </li>
                    <li>
                      <span className="elementText">4 - 6 Days</span>
                      <strong className="elementBold">12% Off</strong>
                    </li>
                    <li>
                      <span className="elementText">7 - 11 Days</span>
                      <strong className="elementBold">18% Off</strong>
                    </li>
                    <li>
                      <span className="elementText">12 - 18 Days</span>
                      <strong className="elementBold">25% Off</strong>
                    </li>
                    <li>
                      <span className="elementText">20+ Days</span>
                      <strong className="elementBold">30% Off</strong>
                    </li>
                  </ul>
                </div>
                <div
                  className="offerBox"
                  style={{
                    backgroundImage: `url(${world})`,
                    backgroundColor: "#021F2C",
                  }}
                >
                  <div className="offerInner">
                    <h5>Discount Offer</h5>
                    <span className="text">17%</span>
                    <p>Off For All Types Of Vehicles</p>
                    <p>* New Customers Only</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        
      <ColorDegreeView />
      
    </>
  );
};

export default VehicleDetails;
