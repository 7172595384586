import React from "react";
import ThreeSixty from "react-360-view";
import rotate from "../../../assets/images/download.png";
const basePath = "https://fastly-production.24c.in/webin/360";

const ColorDegreeView = () => {

  return (
    <>
      <div className="bgGray sectionPD">
        <div className="container">
          <div className="colorDegreeInfo">
            <div className="row">
              <div className="col-lg-12">
                <div className="sectionTitle text-center">
                  <h2>COLOURS & 360° VIEW</h2>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="colorDegreeName">
                  <h4>Colors</h4>
                  <ul>
                    <li className="red">
                      <span className="color"></span>
                      <span className="name">red</span>
                    </li>
                    <li className="blue">
                      <span className="color"></span>
                      <span className="name">Blue</span>
                    </li>
                    <li className="golden">
                      <span className="color"></span>
                      <span className="name">Golden</span>
                    </li>
                    <li className="white">
                      <span className="color"></span>
                      <span className="name">White</span>
                    </li>
                    <li className="green">
                      <span className="color"></span>
                      <span className="name">green</span>
                    </li>
                    <li className="black">
                      <span className="color"></span>
                      <span className="name">black</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="rotateImage">
                  <ThreeSixty
                    amount={75}
                    imagePath={basePath}
                    fileName="output_{index}.jpeg"
                    spinReverse
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="colorDegreeSide">
                  <ul>
                    <li>XSENS WITH PROGRAMMED FUEL INJECTION</li>
                    <li>9% MORE MILEAGE</li>
                    <li>5 YEAR WARRANTY</li>
                    <li>TRUSTED BY MILLIONS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColorDegreeView;
