import React from "react";
import mat from "../../../assets/images/acces/mat.png";
import windshield from "../../../assets/images/acces/windshield.png";
import mentorship from "../../../assets/images/acces/mentorship.png";
import unlock from "../../../assets/images/acces/unlock.png";
import world from "../../../assets/images/cover/world.png";

const Accessories = () => {
  return (
    <>
      <section className=" availSec accessSec bgGray sectionPD" style={{ backgroundImage: `url(${world})`, backgroundColor: "#021F2C" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="sectionTitle">
                <h4 className="text-white">Key Features</h4>
                <div className="accessList">
                  <ul>
                    <li>
                      <img
                        src={windshield}
                        alt="Key Features"
                        className="img-fluid"
                      />
                      <h6>Wide front glass with wiper</h6>
                    </li>
                    <li>
                      <img
                        src={unlock}
                        alt="Key Features"
                        className="img-fluid"
                      />
                      <h6>Central Locking</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sectionTitle">
                <h4 className="text-white">Our Accessories</h4>
                <div className="accessList">
                  <ul>
                    <li>
                      <img
                        src={mentorship}
                        alt="Accessories"
                        className="img-fluid"
                      />
                      <h6>Career</h6>
                    </li>
                    <li>
                      <img src={mat} alt="Accessories" className="img-fluid" />
                      <h6>Mats</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accessories;
