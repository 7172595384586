import React from "react";
import Slider from "react-slick";

const DealerTestimonial = () => {
  var settings = {
    dots: true,
    infinite: true,
    arrow: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };
  return (
    <>
      <section className="dealersTestimonialSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h6>Our Testimonial</h6>
                <h2>Exciting Dealer’s Testimonials</h2>
              </div>
              <Slider {...settings}>
                <div className="dealerTestItem">
                  <h3>Mr. Sanjay Verma </h3>
                  <h6>Exclusive Dealer, Raipur</h6>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Magni porro et corrupti debitis commodi odit cupiditate,
                    quibusdam natus, dolore autem magnam rem quos aut quod
                    sapiente amet ea facere exercitationem?
                  </p>
                </div>
                <div className="dealerTestItem">
                  <h3>Mr. Wakeel Ahmad</h3>
                  <h6>Dealer, Jhansi</h6>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Magni porro et corrupti debitis commodi odit cupiditate,
                    quibusdam natus, dolore autem magnam rem quos aut quod
                    sapiente amet ea facere exercitationem?
                  </p>
                </div>
                <div className="dealerTestItem">
                  <h3>Mr. Amit Kumar </h3>
                  <h6>Dealer, Jammu</h6>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Magni porro et corrupti debitis commodi odit cupiditate,
                    quibusdam natus, dolore autem magnam rem quos aut quod
                    sapiente amet ea facere exercitationem?
                  </p>
                </div>
                <div className="dealerTestItem">
                  <h3>Mr. Pardeep Kumar</h3>
                  <h6>Dealer, Assam</h6>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Magni porro et corrupti debitis commodi odit cupiditate,
                    quibusdam natus, dolore autem magnam rem quos aut quod
                    sapiente amet ea facere exercitationem?
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DealerTestimonial;
