import React, { useEffect, useState } from "react";

const BackTop = () => {
  const [backTop, setBackTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        setBackTop(true);
      } else {
        setBackTop(false);
      }
    });
  }, []);
  const handleBack = () => {
    window.scrollTo(0, 0)
  }
  return (
    <>
      {backTop && (
        <div className="backTop" onClick={handleBack}>
          <i className="ri-arrow-up-s-line"></i>
        </div>
      )}
    </>
  );
};

export default BackTop;
