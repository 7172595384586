import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import news1 from "../../assets/images/news/one.webp";
import news2 from "../../assets/images/news/two.webp";
import news3 from "../../assets/images/news/three.webp";
import news4 from "../../assets/images/news/four.webp";

const Dealership = () => {
  var settings = {
    dots: false,
    infinite: true,
    arrow: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };
  var settings1 = {
    dots: true,
    infinite: true,
    arrow: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 1500,
  };
  return (
    <>
      <div className="dealershipSec sectionPD">
        <div className="container">
          <div className="ourTrusted">
            <h3>Our Trusted Supplier</h3>
            <ul>
              <li>
                <a href="/">
                  <img
                    src="https://maqAutoindia.com/wp-content/uploads/2022/03/lohum-150x150.png"
                    alt="Brand"
                    className="img-fluid"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://maqAutoindia.com/wp-content/uploads/2022/03/ceat-1-150x150.png"
                    alt="Brand"
                    className="img-fluid"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://maqAutoindia.com/wp-content/uploads/2022/03/okaya-150x150.png"
                    alt="Brand"
                    className="img-fluid"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://maqAutoindia.com/wp-content/uploads/2022/03/eastman-150x150.png"
                    alt="Brand"
                    className="img-fluid"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://maqAutoindia.com/wp-content/uploads/2022/03/munjal-150x150.png"
                    alt="Brand"
                    className="img-fluid"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://maqAutoindia.com/wp-content/uploads/2022/03/motherson-150x150.png"
                    alt="Brand"
                    className="img-fluid"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://maqAutoindia.com/wp-content/uploads/2022/03/bosch-logo-150x150.png"
                    alt="Brand"
                    className="img-fluid"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="reasons">
            <div className="ourTrusted">
              <h3>8 Reasons</h3>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>Designed in india, Made in india</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>Multiple products one new launch every 3 month</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>tested for 3 years</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>best in class features </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>30 plus accessories - customizable </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>highest warranty 3 years</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>attractive consumer schemes </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>transparent relationship</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="investSec bgGray sectionPD">
        <div className="container">
          <div className="reasons">
            <div className="sectionTitle">
              <h3>We invest for your success</h3>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>Designed in india, Made in india</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>Multiple products one new launch every 3 month</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>tested for 3 years</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>best in class features </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>30 plus accessories - customizable </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>highest warranty 3 years</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>attractive consumer schemes </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="reasonCard">
                  <div className="icon">Icon</div>
                  <div className="name">
                    <p>transparent relationship</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="NewsSec sectionPD">
        <div className="container">
          <div className="sectionTitle">
            <h3>We are in News</h3>
          </div>
          <Slider {...settings}>
            <div className="newsItem">
              <Link to={"/"}>
                <img src={news1} alt="" className="img-fluid" />
              </Link>
            </div>
            <div className="newsItem">
              <Link to={"/"}>
                <img src={news2} alt="" className="img-fluid" />
              </Link>
            </div>
            <div className="newsItem">
              <Link to={"/"}>
                <img src={news3} alt="" className="img-fluid" />
              </Link>
            </div>
            <div className="newsItem">
              <Link to={"/"}>
                <img src={news4} alt="" className="img-fluid" />
              </Link>
            </div>
          </Slider>
        </div>
      </div>

      <div className="dealerTestSec sectionPD">
        <div className="container">
          <div className="sectionTitle">
            <h3>Exciting Dealer’s Testimonials</h3>
          </div>
          <Slider {...settings1}>
            <div className="dealerTestItem">
              <h3>Mr. Sanjay Verma </h3>
              <h6>Exclusive Dealer, Raipur</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
                porro et corrupti debitis commodi odit cupiditate, quibusdam
                natus, dolore autem magnam rem quos aut quod sapiente amet ea
                facere exercitationem?
              </p>
            </div>
            <div className="dealerTestItem">
              <h3>Mr. Wakeel Ahmad</h3>
              <h6>Dealer, Jhansi</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
                porro et corrupti debitis commodi odit cupiditate, quibusdam
                natus, dolore autem magnam rem quos aut quod sapiente amet ea
                facere exercitationem?
              </p>
            </div>
            <div className="dealerTestItem">
              <h3>Mr. Amit Kumar </h3>
              <h6>Dealer, Jammu</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
                porro et corrupti debitis commodi odit cupiditate, quibusdam
                natus, dolore autem magnam rem quos aut quod sapiente amet ea
                facere exercitationem?
              </p>
            </div>
            <div className="dealerTestItem">
              <h3>Mr. Pardeep Kumar</h3>
              <h6>Dealer, Assam</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
                porro et corrupti debitis commodi odit cupiditate, quibusdam
                natus, dolore autem magnam rem quos aut quod sapiente amet ea
                facere exercitationem?
              </p>
            </div>
          </Slider>
        </div>
      </div>

      <div className="corporateSec bgGray sectionPD">
        <div className="container">
          <div className="sectionTitle">
            <h3>Corporate Video</h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="corporateItem">
                <iframe
                  width={"100%"}
                  height={315}
                  src="https://www.youtube.com/embed/hQQBAcQwwyY?si=lWWnGu9L-cXFV-Pu"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dealership;
