import React, { useState } from "react";

const Feedback = () => {
  const [show, setShow] = useState(false);
  const handleFeed = () => {
    setShow(!show);
  };
  const removeFeed = () => {
    setShow(false);
  };
  return (
    <>
      <div className={show ? "feedbackInfo active" : "feedbackInfo"}>
        <span className="feedbackBtn" onClick={handleFeed}>
          Feedback
        </span>
        <div className="feedbackArea">
          <div className="headerFeed" onClick={removeFeed}>
            <i className="ri-close-fill"></i>
          </div>
          <div className="rating">
            <h6>Please rate your overall website experience</h6>
            <i className="ri-star-fill"></i>
            <i className="ri-star-fill"></i>
            <i className="ri-star-fill"></i>
            <i className="ri-star-line"></i>
            <i className="ri-star-line"></i>
          </div>

          <div className="enhance">
            <h6>Customer Satisfaction</h6>

            <div className="form-check">
              <input className="form-check-input" type="radio" />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Poor
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Fair
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Good
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Very Good
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Excellent
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
