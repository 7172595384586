import React from "react";

const Media = () => {
  return (
    <>
      <section className="mediaSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-5" >
              <div className="sectionTitle mb-4">
                <h6>Enterprises</h6>
                <h3>Public Sector Enterprises</h3>
              </div>
              <div className="mediaList">
                <ul>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="sectionTitle mb-4">
                <h3>Media Presence</h3>
              </div>
              <div className="mediaList mediaList1">
                <ul>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                        alt="Brand"
                      />
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Media;
