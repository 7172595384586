import React from "react";
import { Link } from "react-router-dom";
import app1 from "../../../assets/images/app1.png";
import world from "../../../assets/images/cover/world.png";
import android from "../../../assets/images/google-play.png";
import ios from "../../../assets/images/app-store.png";

const Available = () => {
  return (
    <>
      <section
        className="availSec sectionPD"
        style={{ backgroundImage: `url(${world})`, backgroundColor: "#021F2C" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="availImg">
                <img src={app1} alt="About Us" className="img-fluid" />
                <div className="text">
                  <h5><strong>FREE</strong> Download</h5>
                  <p>IOS & Andorid </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="availContent">
                <h6>Download Our App</h6>
                <h2>Find An Available E-Rickshaw Near You With Our App</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore
                </p>

                <div className="downloadSec">
                    <Link to={"/"}> <img src={android} alt="Android" className="img-fluid" /> </Link>
                    <Link to={"/"}> <img src={ios} alt="IOS" className="img-fluid" /> </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Available;
