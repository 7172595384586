import React from "react";
import review1 from "../../../assets/images/review/testimonial-A8E2W8S.jpg";
import review2 from "../../../assets/images/review/testimonial-M6NJPEF.png";
import review3 from "../../../assets/images/review/testimonial-YV9ABZR.png";

const Review = () => {
  return (
    <>
      <section className="reviewSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="sectionTitle">
                <h6>Customers Feedback!</h6>
                <h2>Our Customer Reviews</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="reviewCard">
                <div className="para">
                  <p>
                    Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua enim ad minim veniam
                  </p>
                </div>
                <div className="author">
                  <div className="left">
                    <div className="image">
                      <img src={review1} alt="" className="img-fluid" />
                    </div>
                    <div className="text">
                      <h5>Dave Beech</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="reviewCard">
                <div className="para">
                  <p>
                    Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua enim ad minim veniam
                  </p>
                </div>
                <div className="author">
                  <div className="left">
                    <div className="image">
                      <img src={review2} alt="" className="img-fluid" />
                    </div>
                    <div className="text">
                      <h5>Ailish Whittle</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="reviewCard">
                <div className="para">
                  <p>
                    Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua enim ad minim veniam
                  </p>
                </div>
                <div className="author">
                  <div className="left">
                    <div className="image">
                      <img src={review3} alt="" className="img-fluid" />
                    </div>
                    <div className="text">
                      <h5>Xavier Mcfarla</h5>
                      <p>Entrepreneur</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Review;
