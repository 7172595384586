import React from 'react'
import Home from '../../components/home/Home'

const HomePage = () => {
  return (
    <>
        <Home />
    </>
  )
}

export default HomePage