import React from "react";
import { useLocation } from "react-router-dom";
import world from "../../assets/images/banner/hero.jpg";

const Login = () => {
  const location = useLocation();
  if (location.pathname == "/login") {
    // document.body.classList.add("loginPage");
  } else {
    document.body.classList.remove("loginPage");
  }
  return (
    <>
      <section className="loginSec coverBg bgGray sectionPD" style={{backgroundImage: `url(https://www.imageshine.in/uploads/gallery/Gray-abstract-wireframe-technology-background.jpg)`}}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="loginInfo  mt-5">
                <div className="title">
                  <h2>SignIn</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Animi perferendis ab adipisci nihil totam
                  </p>
                </div>
                <form className="loginForm">
                  <div className="form-group mb-3">
                    <label htmlFor="Mobile Number">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Mobile Number"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="Enter Password">Enter Password</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <button type="button" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="loginInfo mt-5">
                
                <form className="loginForm">
                <div className="title">
                  <h2>SignUp</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Animi perferendis ab adipisci nihil totam
                  </p>
                </div>
                  <div className="form-group mb-3">
                    <label htmlFor="Enter Name">Enter Name</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="Mobile Number">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Mobile Number"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="Enter Password">Enter Password</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <button type="button" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
