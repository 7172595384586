import React from 'react'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'
import Career from '../../components/career/Career'

const CareerPage = () => {
  return (
    <>
        <BreadCrumb title="Career" />
        <Career />
    </>
  )
}

export default CareerPage