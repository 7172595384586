import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <footer className="footer sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footerBox">
                <div className="icon">
                  <i className="ri-file-list-3-line"></i>
                </div>
                <h3>
                  Sign up for <br /> Electric Rikshaw
                </h3>
                <form className="newsLetter">
                  <label htmlFor="Email">Email Address</label>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="form-control"
                  />
                  <button className="btn btn-primary">Subscribe</button>
                </form>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory ps-3">
                <h4>Maq auto india</h4>
                <span className="line"></span>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Earum id enim, excepturi temporibus modi...
                </p>
                <Link to={"about"} className="mb-5">
                  Get a Quote <FaLongArrowAltRight />
                </Link>

                <h4>Login</h4>
                <span className="line"></span>
                <ul>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Dealer
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Customer
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <h4>Useful Links</h4>
                <span className="line"></span>
                <ul>
                  <li>
                    <Link to={"/about"}>
                      <i className="ri-arrow-right-double-line"></i> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to={"/vehicles"}>
                      <i className="ri-arrow-right-double-line"></i> Our
                      Vehicles
                    </Link>
                  </li>
                  <li>
                    <Link to={"/team"}>
                      <i className="ri-arrow-right-double-line"></i> Our Team
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> News &
                      Media
                    </Link>
                  </li>
                  <li>
                    <Link to={"/contact"}>
                      <i className="ri-arrow-right-double-line"></i> Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to={"/video-gallery"}>
                      <i className="ri-arrow-right-double-line"></i> Video
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to={"/photo-gallery"}>
                      <i className="ri-arrow-right-double-line"></i> Photo
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to={"/view-all-clients"}>
                      <i className="ri-arrow-right-double-line"></i> Our Clients
                    </Link>
                  </li>
                  <li>
                    <Link to={"/career"}>
                      <i className="ri-arrow-right-double-line"></i> Careers
                    </Link>
                  </li>
                  <li>
                    <Link to={"/dealer-testimonial"}>
                      <i className="ri-arrow-right-double-line"></i> Dealer's
                      Testimonial
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <h4>Quick Contact</h4>
                <span className="line"></span>

                <p className="address">
                98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                </p>
                <p>
                  If you have any questions or need help, feel free to contact
                  with our team.
                </p>
                <a href="#" className="number">
                  (+91) 8851746286
                </a>
              </div>
            </div>
            <div className="col-md-12">
              <div className="footerCategory">
                <h4>Our Products</h4>
                <span className="line"></span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <div className="subTitle">
                  <h6>E-Rickshaw</h6>
                </div>
                <ul>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> ER-Bolt
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> ER-Warrior
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> ER-Thunder
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> ER-Boxy
                    </Link>
                  </li>
                  <li>
                    <Link to={"/video-gallery"}>
                      <i className="ri-arrow-right-double-line"></i> ER-Tejas
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <div className="subTitle">
                  <h6>Golf Cart</h6>
                </div>
                <ul>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Golf Cart
                      Bolt
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Golf Cart
                      Warrior
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Golf Cart
                      Thunder
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i>Golf Cart
                      ER-Boxy
                    </Link>
                  </li>
                  <li>
                    <Link to={"/video-gallery"}>
                      <i className="ri-arrow-right-double-line"></i> ER-Tejas
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <div className="subTitle">
                  <h6>E-Scooter</h6>
                </div>
                <ul>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq Prime
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq Neo
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq Milan
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq Apollo
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerCategory">
                <div className="subTitle">
                  <h6>Maq 900</h6>
                </div>
                <ul>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq 900
                      Prime
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq 900 Neo
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq 900
                      Milan
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <i className="ri-arrow-right-double-line"></i> Maq 900
                      Apollo
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="copyRightSec">
            <div className="list">
              <ul>
                <li>
                  <Link to={"/policy"}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={"/condition"}>Terms of Conditions </Link>
                </li>
                <li>
                  <Link to={"/"}> Blog</Link>
                </li>
                <li>
                  <Link to={"/testimonial"}> Testimonial</Link>
                </li>
              </ul>
              <p>
                Copyright <i className="ri-copyright-line"></i> 2020 Maq Auto,
                All Rights Reserved.
              </p>
            </div>
            <div className="social-style1">
              <Link to="/">
                <i className="ri-facebook-fill"></i>
              </Link>
              <Link to="/">
                <i className="ri-twitter-fill"></i>
              </Link>
              <Link to="/">
                <i className="ri-instagram-fill"></i>
              </Link>
              <Link to="/">
                <i className="ri-linkedin-fill"></i>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
