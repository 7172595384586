import React from "react";

import img1 from "../.../../../../assets/images/collection/1.webp";

const VehicleImg = () => {
  return (
    <>
      <div className="VehicleImg">
        <img src={img1} alt="Vehicle" className="img-fluid" />
      </div>
    </>
  );
};

export default VehicleImg;
