import React from "react";

const Video = () => {
  return (
    <>
      <section className="aboutSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h6>Our Story</h6>
                <h2>Video Gallery</h2>
                <p>
                  maqAuto started in early 2014 as a dream organization & today
                  has grown to become one of the industry leading players with
                  PAN India presence. maqAuto is one of the pioneers in Electric
                  Rickshaw Manufacturer, spare parts & accessories in India. Our
                  products are i-CAT (International Centre for Automotive
                  Technology) certified by Govt of India and positioned as a
                  Electric Vehicle company having patented technologies & state
                  of the art design capabilities. We take pride in manufacturing
                  the widely popular Electric Rickshaw for passengers and
                  Electric Cart for loading purposes that are well known for
                  their eco-friendly performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Video;
