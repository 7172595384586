import React from "react";

const Career = () => {
  return (
    <>
      <section className="careerSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h6>Career</h6>
                <h2>
                  We Build Electric Vehicles (EV) To Create A Opportunity &
                  Sustainability In Society
                </h2>
                <p>
                  More than 5.5 million people die prematurely each year due to
                  air pollution with over half of those deaths occurring in
                  China and India, two of the world’s fastest-growing economies.
                  Air pollution caused by gas exhaust from gasoline vehicles has
                  become a serious problem causing many to cover their faces
                  with masks while riding on the street.
                </p>
                <p>
                  The REVolution from traditional gasoline-powered vehicles to
                  electric vehicles (EVs) has been a gradual process for some –
                  and an exciting leap for others. With increasing innovation
                  and environmental awareness, EVs have never been more safer,
                  sleeker, or exciting to drive.
                </p>
                <p>
                  Boasting features like immediate torque, silent ride, and
                  premium performance, EVs also have lower fuel and maintenance
                  costs. And consumers ultimately garner social pride and
                  responsibility from creating a better, healthier planet. For
                  all of these reasons and more, EVs have caught the attention
                  of commuters. We at maqAUTO launched India’s first electric
                  three-wheeler powered by Li-Ion battery in 2014. With our
                  proprietary battery swapping technology, refueling time can be
                  reduce We have a well equipped manufacturing unit along with a
                  team of highly talented engineers and staff. The whole team is
                  continuously working on making our vehicles to shape them
                  better and more advanced to contribute in making a cleaner and
                  greener tomorrow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
