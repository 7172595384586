import React from "react";
import BreadCrumb from "../../components/shared/breadcrumb/BreadCrumb";
import Contact from "../../components/contact/Contact";

const ContactPage = () => {
  return (
    <>
      <BreadCrumb title="Contact" />
      <Contact />
    </>
  );
};

export default ContactPage;
