import React from 'react'
import Policy from '../../components/policy/Policy'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const PolicyPage = () => {
  return (
    <>
        <BreadCrumb title="Privacy Policy" />
        <Policy />
    </>
  )
}

export default PolicyPage