import React from "react";

const AllClients = () => {
  return (
    <>
      <section className="brandsSec sectionPD">
        <div className="container">
          <div className="brandsItem">
            <div className="fisherman-content mb-4">
              <h3>Our Clients</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Suscipit, facere eaque.
              </p>
            </div>
            <ul className="viewAllClient">
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/1.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/1.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/1.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/1.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                    alt="Brand"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllClients;
