import React from "react";
import { Modal } from "react-bootstrap";

const BookRide = ({ data }) => {
  const { show, handleClose } = data;
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="bookRideModal">
        <Modal.Header closeButton>
          <Modal.Title>Request a Call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bookFormBox m-0">
           
            <form className="bookForm row">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="Name">Name</label>
                  <input
                    type="text"
                    placeholder="Your Name"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Phone Number">Phone Number</label>
                  <input
                    type="number"
                    placeholder="Your Phone Number"
                    className="form-control"
                  />
                </div>
                
                <div className="form-group mb-3">
                  <label htmlFor="Select State">Select State</label>
                  <select className="form-select">
                    <option defaultValue="Maharashtra">Maharashtra</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="Email">Email</label>
                  <input
                    type="mail"
                    placeholder="Your Email"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Enter OTP">Enter OTP</label>
                  <input
                    type="number"
                    placeholder="Enter Your OTP"
                    className="form-control"
                  />
                </div>
                
                <div className="form-group mb-3">
                  <label
                    htmlFor="Select State
                      "
                  >
                    Select State
                  </label>
                  <select className="form-select">
                    <option defaultValue="Mumbai">Mumbai</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                
                <div className="form-group mb-3">
                  <button className="readMore b-0">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookRide;
