import React from "react";

import review1 from "../../assets/images/review/testimonial-A8E2W8S.jpg";
import review2 from "../../assets/images/review/testimonial-M6NJPEF.png";
import review3 from "../../assets/images/review/testimonial-YV9ABZR.png";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <>
      <section className="teamSec bgGray sectionPD">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <div className="sectionTitle">
                <h6>Team Member</h6>
                <h2>Our Team</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review1} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Dave Beech</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review2} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Ailish Whittle</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review3} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Xavier Mcfarla</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review1} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Dave Beech</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review2} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Ailish Whittle</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review3} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Xavier Mcfarla</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review1} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Dave Beech</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="teamCard">
                <div className="header">
                  <div className="image">
                    <img src={review1} alt="" className="img-fluid" />
                  </div>
                  <div className="text">
                    <h5>Dave Beech</h5>
                    <p>Entrepreneur</p>
                  </div>
                </div>
                <div className="social-style1">
                  <Link to="/">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link to="/">
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
