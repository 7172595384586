import React from "react";

const Condition = () => {
  return (
    <>
      <section className="aboutSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h6>Our Conditions</h6>
                <h2>Terms & Condition</h2>
                <p>
                  maqAuto started in early 2014 as a dream organization & today
                  has grown to become one of the industry leading players with
                  PAN India presence. maqAuto is one of the pioneers in Electric
                  Rickshaw Manufacturer, spare parts & accessories in India. Our
                  products are i-CAT (International Centre for Automotive
                  Technology) certified by Govt of India and positioned as a
                  Electric Vehicle company having patented technologies & state
                  of the art design capabilities. We take pride in manufacturing
                  the widely popular Electric Rickshaw for passengers and
                  Electric Cart for loading purposes that are well known for
                  their eco-friendly performance.
                </p>
                <p>
                  We are offering very unique and innovative designs not like
                  other manufacturers whose designs are more or less the same
                  like ordinary E Rickshaw Models. Our policy is to serve
                  customers with the best of our efforts and deliver them one of
                  the best products and services which helps us to earn a great
                  respect amongst people.
                </p>
                <p>
                  Our Technical team is always working on developing our
                  vehicles to give them unique and advanced looks along with
                  high class performance and contributing to the green
                  environment concept. We have a well equipped manufacturing
                  unit along with a team of highly talented engineers and staff.
                  The whole team is continuously working on making our vehicles
                  to shape them better and more advanced to contribute in making
                  a cleaner and greener tomorrow. We are an emerging and one of
                  the most promising manufacturing firms which specializes in
                  E-Rickshaws.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Condition;
