import React from "react";
import gallery1 from "../../assets/images/gallery/1.webp";
import gallery2 from "../../assets/images/gallery/2.webp";
import gallery3 from "../../assets/images/gallery/3.webp";
import gallery4 from "../../assets/images/gallery/4.webp";
import gallery5 from "../../assets/images/gallery/5.webp";
import gallery6 from "../../assets/images/gallery/6.webp";

const Photo = () => {
  return (
    <>
      <section className="aboutSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h2>Photo Gallery</h2>
                <p>
                  maqAuto started in early 2014 as a dream organization & today
                  has grown to become one of the industry leading players with
                  PAN India presence. maqAuto is one of the pioneers in Electric
                  Rickshaw Manufacturer, spare parts & accessories in India. Our
                  products are i-CAT (International Centre for Automotive
                  Technology) certified by Govt of India and positioned as a
                  Electric Vehicle company having patented technologies & state
                  of the art design capabilities. We take pride in manufacturing
                  the widely popular Electric Rickshaw for passengers and
                  Electric Cart for loading purposes that are well known for
                  their eco-friendly performance.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="galleryCard">
                <div className="galleryImg">
                  <img src={gallery1} alt="Gallery" className="img-fluid" />
                </div>
                <div className="galleryImg">
                  <img src={gallery2} alt="Gallery" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="galleryCard">
                <div className="galleryImg">
                  <img src={gallery3} alt="Gallery" className="img-fluid" />
                </div>
                <div className="galleryImg">
                  <img src={gallery4} alt="Gallery" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="galleryCard">
                <div className="galleryImg">
                  <img src={gallery5} alt="Gallery" className="img-fluid" />
                </div>
                <div className="galleryImg">
                  <img src={gallery6} alt="Gallery" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Photo;
