import React from "react";
import { Link } from "react-router-dom";
import app from "../../../assets/images/app-2.png";

import hero from "../../../assets/images/banner/hero.jpg";

const Banner = () => {
  return (
    <>
      <section className="banner" style={{ backgroundImage: `url(${hero})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="bannerContent">
                <h6>THE BEST WAY TO</h6>
                <h1>Start Your Ride With E-Rickshaw</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                  Utenimad minim veniam, quis nostrud exercitation dolore magna
                </p>
                <Link to="/" className="readMore">
                  Get Started
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bannerImg">
                {/* <img
              src={app}
              alt="Img"
              className="img-fluid"
            /> */}
                <div className="circleText">
                  <span>
                    85 <sup>%</sup>
                  </span>
                  <p>Clients</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
