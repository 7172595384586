import React from 'react'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'
import VehicleDetails from '../../components/vehicle-details/VehicleDetails'

const VehicleDetailsPage = () => {
  return (
    <>
      <BreadCrumb title="Vehicle Details" />
      <VehicleDetails />
    </>
  )
}

export default VehicleDetailsPage