import React from 'react'
import About from '../../components/about/About'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const AboutPage = () => {
  return (
    <>
        <BreadCrumb title="about us" />
        <About />
    </>
  )
}

export default AboutPage