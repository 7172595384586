
import React from "react";
import BreadCrumb from "../../components/shared/breadcrumb/BreadCrumb";
import DealerTestimonial from "../../components/dealer-testimonial/DealerTestimonial";

const DealerTestimonialPage = () => {
  return (
    <>
      <BreadCrumb title="Dealer Testimonial" />
      <DealerTestimonial />
    </>
  );
};

export default DealerTestimonialPage;
