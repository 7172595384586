
import React from "react";
import BreadCrumb from "../../components/shared/breadcrumb/BreadCrumb";
import Dealership from "../../components/dealership/Dealership";

const DealershipPage = () => {
  return (
    <>
      <BreadCrumb title="Dealership" />
      <Dealership />
    </>
  );
};

export default DealershipPage;
