
import React from 'react'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'
import AllClients from '../../components/all-clients/AllClients';

const AllClientsPage = () => {
  return (
    <>
        <BreadCrumb title="All Clients" />
        <AllClients />
    </>
  )
}

export default AllClientsPage