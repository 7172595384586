import React from 'react'
import Photo from '../../components/photo/Photo'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const PhotoPage = () => {
  return (
    <>
        <BreadCrumb title="Photo Gallery" />
        <Photo />
    </>
  )
}

export default PhotoPage