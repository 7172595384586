import React from "react";
import hero from "../../../assets/images/banner/hero1.jpg";

const BreadCrumb = ({ title }) => {
  return (
    <>
      <div
        className="breadCrumbSec"
        style={{ backgroundImage: `url(${hero})` }}
      >
        <div className="container">
          <div className="breadCrumbInfo">
            <h3>{title}</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus dolores impedit rem labore incidunt cumque fuga corrupti? Dignissimos temporibus facilis</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;
