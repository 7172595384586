import React from 'react'
import Team from '../../components/team/Team'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const TeamPage = () => {
  return (
    <>
        <BreadCrumb title="Our Team" />
        <Team />
    </>
  )
}

export default TeamPage