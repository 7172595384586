import React from 'react'
import Testimonial from '../../components/testimonial/Testimonial'
import BreadCrumb from '../../components/shared/breadcrumb/BreadCrumb'

const TestimonialPage = () => {
  return (
    <>
        <BreadCrumb title="Testimonial" />
        <Testimonial />
    </>
  )
}

export default TestimonialPage